import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/query/react';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

import config from 'config/config';

import { API_TAGS, AUTH_API_PATH, HTTP_METHODS } from 'constants/api.constants';
import { ROUTES_ERROR, ROUTES_PUBLIC } from 'constants/routes.constants';
import store from 'store/store';
import { setRedirectUrl } from 'store/utils/slices/utils-slice';
import { STORE_SLICES } from 'constants/store.constants';
import { Mutex } from 'async-mutex';
import { AuthState, setTokens } from 'features/Auth/store/slices/auth-slice';
import { Tokens } from 'features/Auth/types/auth.types';
import { notification } from 'antd';
import React from 'react';
import { RESPONSE_TYPE, ToastError } from 'types/error.types';
import MessageTitleComponent from 'components/general/MessageTitleComponent';
import MessageDescriptionComponent from 'components/general/MessageDescriptionComponent';
import MessageIconComponent from 'components/general/MessageIconComponent';
import i18n from 'i18n';

export interface IQueryArgs extends FetchArgs {
  apiUrl?: string;
}

const channel = new BroadcastChannel('auth');

const getToken = () => {
  const sliceName = STORE_SLICES.AUTH;
  const savedState = localStorage.getItem(sliceName);

  const parsedSavedState = savedState
    ? (JSON.parse(savedState) as AuthState)
    : null;

  const { tokens } = parsedSavedState || {};

  return tokens;
};

export const redirectOnError = async (status: number) => {
  const tokens = store.getState()[STORE_SLICES.AUTH].tokens;
  const redirectUrl = tokens ? ROUTES_ERROR.BASE : ROUTES_PUBLIC.ERROR;

  store.dispatch(setRedirectUrl(`${redirectUrl}/${status}/`));
};

const refreshToken = async (
  args: IQueryArgs,
  api: BaseQueryApi,
  extraOptions: any
) => {
  const baseQuery = await fetchBaseQuery({
    baseUrl: `${config.identityApiUrl}/api/`,
  });

  const response = (await baseQuery(
    {
      url: AUTH_API_PATH.REFRESH,
      method: HTTP_METHODS.POST,
      headers: {
        Authorization: `Bearer ${getToken()?.accessToken}`,
      },

      body: {
        accessToken: getToken()?.accessToken,
        refreshToken: getToken()?.refreshToken,
      },
    },
    api,
    extraOptions
  )) as QueryReturnValue<
    { result: Tokens },
    FetchBaseQueryError,
    FetchBaseQueryMeta
  >;

  if (response.data?.result) {
    const { accessToken, refreshToken } = response.data.result;

    const tokens = {
      accessToken,
      refreshToken,
    };

    localStorage.setItem(STORE_SLICES.AUTH, JSON.stringify({ tokens }));
    store.dispatch(setTokens(tokens));
  }

  if (response.error) {
    throw new Error();
  }
};

const mutex = new Mutex();

const generateNewHeaders = () => {
  const newAccessToken = getToken()?.accessToken;

  return newAccessToken ? { Authorization: `Bearer ${newAccessToken}` } : {};
};

export const showMessage = (
  title: string,
  description: string,
  duration = 5
) => {
  notification.open({
    message: <MessageTitleComponent title={title} />,
    description: <MessageDescriptionComponent description={description} />,
    placement: 'bottomRight',
    closable: true,
    icon: <MessageIconComponent />,
    duration,
    style: {
      padding: '20px 8px',
    },
  });
};

const getMessage = (response: any) => {
  const { errors } = response?.error?.data ?? {};
  errors?.forEach((error: ToastError) => {
    const { title, message: description, type } = error;

    if (type !== RESPONSE_TYPE.ERROR) {
      return;
    }

    showMessage(title, description);
  });
};

const logout = async () => {
  await store.dispatch((api as any).endpoints.authLogout.initiate()).unwrap();

  store.dispatch(api.util.resetApiState());
  store.dispatch(setTokens(null));

  const lang = localStorage.getItem('language');
  const pageSize = localStorage.getItem('pageSize');

  localStorage.clear();
  if (lang) {
    localStorage.setItem('language', lang);
  }
  if (pageSize) {
    localStorage.setItem('pageSize', pageSize);
  }

  store.dispatch(setRedirectUrl(ROUTES_PUBLIC.LOGIN));
  channel.postMessage('logout');
};

export const handle440Error = () => {
  logout();

  showMessage(
    i18n.t('Session expired'),
    i18n.t(
      'Your session has expired for security reasons please log in again to continue'
    ),
    0
  );
};

const baseQueryWithToast: BaseQueryFn<IQueryArgs> = async (
  { apiUrl = config.apiUrl, ...args }: IQueryArgs,
  api: BaseQueryApi,
  extraOptions
) => {
  await mutex.waitForUnlock();
  const token = getToken();

  const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    headers: token
      ? {
          Authorization: `Bearer ${getToken()?.accessToken}`,
        }
      : {},
  });

  let response = await baseQuery(args, api, extraOptions);

  if (response.error) {
    const { status } = response.error;

    if (status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          await refreshToken(args, api, extraOptions);

          const newHeaders = generateNewHeaders();

          response = await baseQuery(
            { ...args, headers: newHeaders },
            api,
            extraOptions
          );
        } catch (error) {
          handle440Error();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();

        const newHeaders = generateNewHeaders();

        response = await baseQuery(
          { ...args, headers: newHeaders },
          api,
          extraOptions
        );
      }
    } else if (status === 400) {
      getMessage(response);
      return response;
    } else if (status === 409) {
      return response;
    } else if (status === 440) {
      handle440Error();
    } else {
      await redirectOnError(status as number);
    }
  }

  return response;
};

const api = createApi({
  baseQuery: baseQueryWithToast,
  endpoints: () => ({}),
  tagTypes: Object.values(API_TAGS),
});

export default api;
