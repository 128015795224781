export enum ROUTE_PATH {
  BASE = '/',
  PUBLIC = '/public',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  VERIFICATION = '/verification',
  CHANGE_PASSWORD_SUCCESS = '/change-password-success',
  HOME = '/home',
  ACTIVE_CLIENT = '/client',
  CAMPAIGNS = '/campaigns',
  DISTRIBUTIONS = '/distributions',
  ITEMS = '/items',
  MASTER_ITEMS = '/master-items',
  LOCATIONS = '/locations',
  USERS = '/users',
  CLIENTS = '/clients',
  ATTRIBUTES = '/attributes',
  MASTER_ALLOCATIONS = '/master-allocations',
  ALLOCATIONS = '/allocations',
  ERROR = '/error',
  CREATE = '/create',
  EDIT = '/edit',
  PREVIEW = '/preview',
}

export const ROUTES_PUBLIC = {
  BASE: ROUTE_PATH.PUBLIC,
  LOGIN: `${ROUTE_PATH.PUBLIC}${ROUTE_PATH.LOGIN}`,
  FORGOT_PASSWORD: `${ROUTE_PATH.PUBLIC}${ROUTE_PATH.FORGOT_PASSWORD}`,
  RESET_PASSWORD: `${ROUTE_PATH.PUBLIC}${ROUTE_PATH.RESET_PASSWORD}`,
  VERIFICATION: `${ROUTE_PATH.PUBLIC}${ROUTE_PATH.VERIFICATION}`,
  CHANGE_PASSWORD_SUCCESS: `${ROUTE_PATH.PUBLIC}${ROUTE_PATH.CHANGE_PASSWORD_SUCCESS}`,
  ERROR: `${ROUTE_PATH.PUBLIC}${ROUTE_PATH.ERROR}`,
};

export const ROUTES_CAMPAIGNS = {
  BASE: ROUTE_PATH.CAMPAIGNS,
  PREVIEW: `${ROUTE_PATH.CAMPAIGNS}${ROUTE_PATH.PREVIEW}`,
};

export const ROUTES_DISTRIBUTIONS = {
  BASE: ROUTE_PATH.DISTRIBUTIONS,
  PREVIEW: `${ROUTE_PATH.DISTRIBUTIONS}${ROUTE_PATH.PREVIEW}`,
};

export const ROUTES_MASTER_ITEMS = {
  BASE: ROUTE_PATH.MASTER_ITEMS,
  PREVIEW: `${ROUTE_PATH.MASTER_ITEMS}${ROUTE_PATH.PREVIEW}`,
};

export const ROUTES_LOCATIONS = {
  BASE: ROUTE_PATH.LOCATIONS,
  PREVIEW: `${ROUTE_PATH.LOCATIONS}${ROUTE_PATH.PREVIEW}`,
};

export const ROUTES_USERS = {
  BASE: ROUTE_PATH.USERS,
};

export const ROUTES_CLIENTS = {
  BASE: ROUTE_PATH.CLIENTS,
};

export const ROUTES_ACTIVE_CLIENT = {
  BASE: ROUTE_PATH.ACTIVE_CLIENT,
};

export const ROUTES_ATTRIBUTES = {
  BASE: ROUTE_PATH.ATTRIBUTES,
  ITEMS: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.ITEMS}`,
  ITEMS_PREVIEW: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.ITEMS}${ROUTE_PATH.PREVIEW}`,
  CREATE_ITEM: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.ITEMS}${ROUTE_PATH.CREATE}`,
  EDIT_ITEM: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.ITEMS}${ROUTE_PATH.EDIT}`,
  LOCATIONS: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.LOCATIONS}`,
  LOCATIONS_PREVIEW: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.LOCATIONS}${ROUTE_PATH.PREVIEW}`,
  CREATE_LOCATION: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.LOCATIONS}${ROUTE_PATH.CREATE}`,
  EDIT_LOCATION: `${ROUTE_PATH.ATTRIBUTES}${ROUTE_PATH.LOCATIONS}${ROUTE_PATH.EDIT}`,
};

export const ROUTES_MASTER_ALLOCATIONS = {
  BASE: ROUTE_PATH.MASTER_ALLOCATIONS,
  PREVIEW: `${ROUTE_PATH.MASTER_ALLOCATIONS}${ROUTE_PATH.PREVIEW}`,
};

export const ROUTES_ERROR = {
  BASE: ROUTE_PATH.ERROR,
};
